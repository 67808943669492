/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, ContactForm, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-dvq1d2 pt--80" name={"uvod-1"} style={{"paddingBottom":41}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/2af316afc7464ef6acbaff4aaf5d5f3e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Kontakt</span>"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":428}} srcSet={"https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=350x_.png 350w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=660x_.png 660w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=860x_.png 860w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=1400x_.png 1400w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=2000x_.png 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">+420 774 344 014,&nbsp;+420 775 967 530</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box" style={{"marginTop":5}} content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">nikola@chalupavojtechov.cz</span><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box" style={{"marginTop":42}} content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">Nikola Skoumalová</span><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" style={{"marginTop":12}} content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">IČO: 17087520\n<br>sídlo Na Šípu 259, 664 81, Ostrovačice\n<br>provozovna Vojtěchov č. ev. 107, 593 01, Lísek&nbsp;<br>bankovní spojení: 123-7366110257/0100 (Komerční banka, a. s.)<br>nejsem plátce DPH&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"8vc1dyakoxa"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Rezervace"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 fs--16" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Datum příjezdu","type":"date","required":true},{"name":"Datum odjezdu","type":"date","required":true},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat rezervaci","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--25" name={"paticka"} style={{"backgroundColor":"rgba(21,21,22,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box fs--20 w--600" content={"<span style=\"color: var(--color-custom-2);\">Chalupavojtechov.cz</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">Vojtěchov č. ev. 107<br>593 01 Lísek<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 lh--16" content={"<br><span style=\"color: rgb(201, 201, 201);\">+420</span> <span style=\"color: var(--color-custom-2);\">774 344 014</span><br><a href=\"mailto:nikola@chalupavojtechov.cz?subject=Zpr%C3%A1va+z+webu\"><span style=\"color: rgb(201, 201, 201);\">nikola</span><span style=\"color: var(--color-custom-2);\">@chalupavojtechov.cz</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}